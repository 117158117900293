<template>
    <div>
        <Container v-if="isshow" :mainpagedata="mainpagedata" :mydefault="mydefault"></Container>
    </div>
</template>

<script>
import Container from '@/components/Container.vue'

export default {
    name: 'yingbipresent',
    data() {
        return {
            mainpagedata: {
                //特殊页面
                showpages: {
                    //判断pages页面，常规通用组件页面显示pages，特殊页面显示special
                    isshowpages: false, //显示pages的内容
                    isshowspecial: true, //显示special的内容
                    mycontrol: 240 //当该页面为special页面内容时，用数字控制页面-详情内容显示
                }
            }, // 当前Pagedata所需的data数据与控制
            mydefault: {
                myheadactive: 'a6', //该字段为string类型，是头部导航key值
                myasideactive: '608' //该字段为string类型，是子页面的id
            }, //本页面的导航默认值-逆向绑定（可优化-通过当前页面的path值，对数据进行筛选对比，将值自动赋值，避免修改路由之后出现页面&导航不对应状况）
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
            isshow: true //用于判断页面模块是否存在
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        }
    },
    components: {
        Container
    }
}
</script>

<style scoped></style>
